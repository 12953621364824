<template>
  <div id="delivery-evidence">
    <div id="header" class="flex f-jsb f-ai">
      <div class="flex f-ai">
        <div id="logo">
          <img src="@/assets/img/logo-light.svg" alt="CFS logo" />
        </div>
        <div id="title">{{ $t('deliveries.deliveryEvidence') }}</div>
      </div>

      <b-button
        type="is-primary"
        icon-left="download"
        :label="$t('global.downloadFile')"
        @click="onDownloadFile()"
        :loading="loading"
        :disabled="loading || !src"
      />
    </div>
    <b-loading v-model="loading" />
    <Picture v-if="src" :src="src" :alt="deliveryId" :loading="loading" />
  </div>
</template>

<script>
import { Picture } from '@/components';

export default {
  components: {
    Picture
  },
  mounted() {
    this.getImageSource();
  },
  data() {
    return {
      loading: false,
      src: ''
    };
  },
  computed: {
    resource() {
      return this.$route.query.resource;
    },
    deliveryId() {
      return this.$route.query.id;
    }
  },
  methods: {
    async getImageSource() {
      this.loading = true;
      try {
        this.src = '';
        const { resource, deliveryId } = this;
        if (resource === 'pod') {
          const { data } = await this.Api.post('/delivery_manifest/reports/pod/presigned', {
            id: deliveryId
          });
          if (data) this.src = data;
        } else if (resource === 'signature') {
          const { data } = await this.Api.get(
            `/delivery_manifest/reports/signature?id=${deliveryId}`
          );
          if (data) this.src = 'data:image/png;base64,' + data;
        }
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    onDownloadFile() {
      const { resource, deliveryId } = this;
      if (resource === 'pod') {
        window.location = this.src;
      } else if (resource === 'signature') {
        let a = document.createElement('a');
        a.href = this.src;
        a.download = `${deliveryId}-${resource}.png`;
        a.click();
      }
    }
  },
  watch: {
    '$route.query'(value) {
      if (value) this.getImageSource();
    }
  }
};
</script>

<style lang="sass" scoped>
#delivery-evidence
  background-color: $main-background
  height: 100vh
  #header
    width: 100%
    height: 80px
    padding: 0 40px
    background: white
    color: $dark
    #logo
      margin-right: 20px
  #title
    font-weight: bold
    font-size: $f-xxl
  ::v-deep
    .Picture, .pe-container
      display: flex
      justify-content: center
      align-items: center
      height: calc(100% - 80px)
      .letters
        height: 50%
        width: 50%
</style>
